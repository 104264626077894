import React from 'react'
import Layout from '../components/layout'
import SlideShow from '../components/SlideShow'
import red from '../images/bg01.jpg'
import blue from '../images/bg02.jpg'
import green from '../images/bg03.jpg'
import '../css/styles.css'
class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = { email: '' }
  }

  componentDidMount() {
    document.body.classList.add('is-preload')
    window.addEventListener('load', this.initialLoadAnimation)
  }

  componentDidUnmount() {
    window.removeEventListener('load', this.initialLoadAnimation)
  }

  initialLoadAnimation = () => {
    window.setTimeout(function() {
      document.body.classList.remove('is-preload')
    }, 100)
  }

  submit(ev) {
    ev.preventDefault()
    return fetch('https://hooks.zapier.com/hooks/catch/3600529/grbjx2', {
      method: 'POST',
      body: JSON.stringify({ email: this.state.email }),
    }).then(() => this.setState({ email: '', signedUp: true }))
  }

  render() {
    return (
      <Layout>
        <header id="header">
          <h1>Blue Tectonics</h1>
          <p>
            Surveys for Democratic political campaigns and nonprofits, optimized
            for converting donors and driving engagement.
          </p>
          <p>Coming soon. Sign up to learn more:</p>
        </header>

        {!this.state.signedUp ? (
          <form
            id="signup-form"
            method="post"
            onSubmit={this.submit.bind(this)}
          >
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              value={this.state.email}
              onChange={ev => this.setState({ email: ev.target.value })}
              required
            />
            <input type="submit" value="Sign Up" />
          </form>
        ) : (
          <p>Thanks for signing up!</p>
        )}

        <SlideShow slideInterval={3400} autoplay slides={[red, blue, green]} />
      </Layout>
    )
  }
}

export default IndexPage
